<template>
  <b-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
    v-if="currentLocale"
  >
    <template #button-content>
      <b-img
        :src="currentLocale ? currentLocale.img : null"
        height="18px"
        width="26px"
        :alt="currentLocale.locale"
      />
      <span class="ml-1">{{ $t(currentLocale.name) }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="
        $i18n.locale = localeObj.locale;
        setLocale(localeObj.locale);
      "
    >
      <b-img
        :src="localeObj ? localeObj.img : null"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ $t(localeObj.name) }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BImg,
  BDropdown,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BDropdown,
  },
  props: {
    urlParams: {
      required: false,
      type: String,
    },
  },
  computed: {
    ...mapGetters(["returnLocale"]),
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  mounted() {
    if (this.urlParams) {
      this.$i18n.locale = this.urlParams;
      this.setLocale(
        this.urlParams == ("pt-BR" || "pt-br") ? "pt" : this.urlParams
      );
    }
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "Inglês",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Espanhol",
      },
      {
        locale: "pt",
        img: require("@/assets/images/flags/pt.png"),
        name: "Português (Brasil)",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
  methods: {
    ...mapActions(["changeUserLocale"]),
    setLocale(locale) {
      localStorage.setItem("newxcoins-locale", locale);
      this.changeUserLocale(locale);
    },
  },
};
</script>
