<template>
  <div class="auth-wrapper auth-v2" v-if="upline">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          :src="imgLogo"
          alt="Logo New X Coins"
          style="max-height: 10rem"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex remove-margin" style="max-height: 100vh;">
        <b-img
          fluid
          :src="imgUrl"
          alt="Register V2"
          style="width: 100%; max-height: 100vh; object-fit: cover;"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-2">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1" style="font-size: 1.2rem">
            {{ $t("Entre na nova era dos investimentos inteligentes!") }} 🤖
          </b-card-title>
          <b-card-text class="mb-2">
            {{
              $t(
                "Junte-se aos nossos robôs traders e maximize seus lucros no mercado financeiro."
              )
            }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <div class="my-1">
              <strong>Upline: {{ upline }}</strong>
            </div>
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- fullname -->
              <label label-for="register-fullname">{{
                $t("Nome completo")
              }}</label>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  mode="eager"
                  name="Fullname"
                  vid="fullname"
                  rules="required|min:6|max:100"
                >
                  <b-form-input
                    id="register-fullname"
                    v-model="fullname"
                    name="register-fullname"
                    :state="errors.length > 0 ? false : null"
                    placeholder="example"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <label label-for="register-email">{{ $t("E-mail") }}</label>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  mode="eager"
                  name="Email"
                  vid="email"
                  rules="required|min:6|max:80|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="example@gmail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <label label-for="register-password">{{ $t("Senha") }}</label>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  mode="eager"
                  name="Password"
                  vid="password"
                  rules="required|min:4"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="terms"
                  name="RgisterPrivacyPolicy"
                  type="checkbox"
                  :state="terms == false ? false : null"
                >
                  {{ $t("Eu concordo com os") }}
                  <b-link>{{ $t("termos de uso") }}</b-link>
                </b-form-checkbox>
                <small class="text-danger" v-if="terms == false">{{
                  $t("É necessário aceitar os termos de uso!")
                }}</small>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || loading || terms == false"
              >
                <div v-if="!loading">
                  {{ $t("Inscrever-se") }}
                </div>
                <div v-else>
                  <b-spinner small type="grow"></b-spinner>
                  {{ $t("Carregando...") }}
                </div>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t("Já tem uma conta?") }}</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;{{ $t("Faça login em vez disso") }}</span>
            </b-link>
          </p>

          <div class="text-center btnLocaleTheme mt-2">
            <Locale :urlParams="this.$route.params.locale" />
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ProjectLogo from "@/layouts/logo/LogoProjectX.vue";
import GoogleLogo from "@/layouts/logo/LogoGoogle.vue";
import ProjectXLogo from "@/layouts/logo/LogoProjectX.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { required, email, min, regex } from "@/libs/validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Locale from "@/layouts/components/Locale.vue";
import DeviceDetector from "device-detector-js";

export default {
  components: {
    ProjectLogo,
    GoogleLogo,
    ProjectXLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,

    // validations
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      fullname: "",
      userEmail: "",
      password: "",
      terms: null,
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      loading: false,
      emailError: "",

      // validation
      required,
      email,
      min,
      regex,

      upline: null,
    };
  },
  computed: {
    ...mapGetters(["returnLocale"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      return require("@/assets/images/business_plan/img_register.jpg");
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/logo/newxcoins_logo_light.png");
      } else {
        return require("@/assets/images/logo/newxcoins_logo_dark.png");
      }
    },
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      localize(oldVal);
    },
  },
  mounted() {
    document.getElementById("loading-bg").style.display = "block";
    this.$store
      .dispatch("verifyUpline", this.$route.params.upline)
      .then((resp) => {
        this.upline = resp;
        document.getElementById("loading-bg").style.display = "none";
      })
      .catch(() => {
        this.$router.push("/login");
        document.getElementById("loading-bg").style.display = "none";
      });
  },
  methods: {
    ...mapActions(["userRegister", "recoverUserData"]),
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          if (!this.terms) {
            this.terms = false;
            this.loading = false;
          } else {
            // const deviceDetector = new DeviceDetector();
            // const userAgent = navigator.userAgent;
            // const device = deviceDetector.parse(userAgent);

            const data = {
              fullname: this.fullname,
              email: this.userEmail.toLowerCase(),
              password: this.password,
              upline: this.$route.params.upline,
              // device_name:
              //   "OS: " +
              //   device.os.name +
              //   " | Device: " +
              //   device.device.type +
              //   " | Client: " +
              //   device.client.type +
              //   " . " +
              //   device.client.name +
              //   " . " +
              //   device.client.version,
              // terms: this.terms,
            };

            this.userRegister(data)
              .then(() => {
                this.recoverUserData()
                  .then((response) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        icon: "CoffeeIcon",
                        variant: "success",
                        title:
                          this.$i18n.t("Bem-vindo ") +
                          response.data.user.fullname,
                        text: this.$i18n.t(
                          "Seu cadastro foi realizado com sucesso!"
                        ),
                      },
                    });
                    this.$ability.update(response.data.user.roles);
                    this.loading = false;
                    this.$router.push("/");
                  })
                  .catch((error) => {
                    if (error.status == 500) {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          icon: "CoffeeIcon",
                          variant: "danger",
                          title: error.data.message,
                        },
                      });
                    } else {
                      this.$refs.registerForm.setErrors(error.data.errors);
                    }
                    this.loading = false;
                  });
              })
              .catch((error) => {
                if (error.status == 500) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      icon: "CoffeeIcon",
                      variant: "danger",
                      title: error.data.message,
                    },
                  });
                } else {
                  this.$refs.registerForm.setErrors(error.data.errors);
                }
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.remove-margin {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.auth-inner .b-img {
  object-fit: cover;
  width: 100%;
  max-height: 100vh; /* Defina a altura máxima como a altura da visualização */
}
</style>
