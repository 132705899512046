var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.upline)?_c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"max-height":"10rem"},attrs:{"fluid":"","src":_vm.imgLogo,"alt":"Logo New X Coins"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex remove-margin",staticStyle:{"max-height":"100vh"},attrs:{"lg":"8"}},[_c('b-img',{staticStyle:{"width":"100%","max-height":"100vh","object-fit":"cover"},attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-2",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.$t("Entre na nova era dos investimentos inteligentes!"))+" 🤖 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "Junte-se aos nossos robôs traders e maximize seus lucros no mercado financeiro." ))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"my-1"},[_c('strong',[_vm._v("Upline: "+_vm._s(_vm.upline))])]),_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('label',{attrs:{"label-for":"register-fullname"}},[_vm._v(_vm._s(_vm.$t("Nome completo")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"mode":"eager","name":"Fullname","vid":"fullname","rules":"required|min:6|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-fullname","name":"register-fullname","state":errors.length > 0 ? false : null,"placeholder":"example"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('label',{attrs:{"label-for":"register-email"}},[_vm._v(_vm._s(_vm.$t("E-mail")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"mode":"eager","name":"Email","vid":"email","rules":"required|min:6|max:80|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"example@gmail.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('label',{attrs:{"label-for":"register-password"}},[_vm._v(_vm._s(_vm.$t("Senha")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"mode":"eager","name":"Password","vid":"password","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"RgisterPrivacyPolicy","type":"checkbox","state":_vm.terms == false ? false : null},model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}},[_vm._v(" "+_vm._s(_vm.$t("Eu concordo com os"))+" "),_c('b-link',[_vm._v(_vm._s(_vm.$t("termos de uso")))])],1),(_vm.terms == false)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("É necessário aceitar os termos de uso!")))]):_vm._e()],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.loading || _vm.terms == false}},[(!_vm.loading)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Inscrever-se"))+" ")]):_c('div',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t("Carregando..."))+" ")],1)])],1)]}}],null,false,3607412108)}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("Já tem uma conta?")))]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Faça login em vez disso")))])])],1),_c('div',{staticClass:"text-center btnLocaleTheme mt-2"},[_c('Locale',{attrs:{"urlParams":this.$route.params.locale}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }